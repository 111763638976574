import React from 'react';
import { Route } from 'react-router-dom';
import ScrollView from './containers/ScrollView';
import MenuBar from './containers/MenuBar';
import routes from './routes';

function TabsRoutes() {
  return (
    <>
      <ScrollView>
        {routes.tabs.map(({
          path, exact, component,
        }) => {
          return (
            <Route
              key={`${path}-${component}`}
              path={path}
              exact={exact}
              component={component}
            />
          );
        })}
      </ScrollView>
      <MenuBar />
    </>
  );
}

export default TabsRoutes;
