import { lazyLoadComponent } from './components/lazyLoadComponent';

export default {
  tabs: [
    {
      path: '/',
      exact: true,
      component: lazyLoadComponent(() => import('./screens/DashboardScreen')),
    },
    {
      path: '/calendar',
      component: lazyLoadComponent(() => import('./screens/CalendarScreen')),
    },
    {
      path: '/profile',
      component: lazyLoadComponent(() => import('./screens/ProfileScreen')),
    },
    {
      path: '/search',
      component: lazyLoadComponent(() => import('./screens/SearchScreen')),
    },
  ],
  modals: [
    {
      path: '/signUp',
      component: lazyLoadComponent(() => import('./screens/SignUpScreen')),
    },
    {
      path: '/companySignUp',
      component: lazyLoadComponent(() => import('./screens/CompanySignUpScreen')),
    },
    {
      path: '/profile/:id',
      component: lazyLoadComponent(() => import('./screens/ProfileScreen')),
    },
    {
      path: '/offer/:id',
      component: lazyLoadComponent(() => import('./screens/OfferScreen')),
    },
  ],

};
