// @flow
import React from 'react';
import MenuIcons from '../../components/MenuIcons';
import MenuTab from '../../components/MenuTab';
import s from './style.module.scss';

function MenuBar() {
  const menu = Object.keys(MenuIcons).map((tab) => {
    return (
      <MenuTab
        key={tab}
        tabRoute={`/${tab === 'home' ? '' : tab}`}
        label={tab}
        className="_t_menuTab"
      >
        {MenuIcons[tab]}
      </MenuTab>
    );
  });
  return (
    <div className={s.menuBar}>
      {menu}
    </div>
  );
}

export default MenuBar;
