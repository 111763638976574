
import React from 'react';
import './App.module.scss';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import routes from './routes';
import TabsRoutes from './TabsRoutes';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        {routes.modals.map(({
          path, exact, component,
        }) => {
          return (
            <Route
              key={`${path}-${component}`}
              path={path}
              exact={exact}
              component={component}
            />
          );
        })}
        <TabsRoutes />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
