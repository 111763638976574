import React, { lazy, Suspense } from 'react';

export const lazyLoadComponent = (component) => {
  const ScreenComp = lazy(component);

  return props => (
    <Suspense fallback={<div>Loading...</div>}>
      <ScreenComp {...props} />
    </Suspense>
  );
};

export default lazyLoadComponent;
