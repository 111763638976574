// @flow
import React from 'react';
import { NavLink } from 'react-router-dom';
import s from './style.module.scss';

type MenuTabProps = {
  label?: string,
  children: Element,
  tabRoute: string,
}

function MenuTab(props: MenuTabProps) {
  const {
    tabRoute,
    label,
    children,
  } = props;

  return (
    <NavLink
      style={{ textDecoration: 'none' }}
      to={tabRoute}
      className={s.menuTab}
      activeClassName={s.menuTab__active}
    >
      <div className={s.menuTab__iconContainer}>
        {children}
      </div>
      {label !== '' && (
        <p className={`${s.menuTab__label} _t_menuTab_label`}>
          {label.charAt(0).toUpperCase() + label.slice(1)}
        </p>
      )}
    </NavLink>
  );
}

MenuTab.defaultProps = {
  label: '',
};

export default MenuTab;
