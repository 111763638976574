// @flow
import React from 'react';
import s from './style.module.scss';

const MENU_HEIGHT = 77;
const BODY_HEIGHT = document.body.clientHeight;

function ScrollView({ children }: { children: React.Node }) {
  return (
    <div
      className={s.scrollView}
      style={{ height: `${BODY_HEIGHT - MENU_HEIGHT}px` }}
    >
      {children}
    </div>
  );
}

export default ScrollView;
